<template>
  <div id="app" :class="classNames">
    <main
      v-bind:class="navigation.isNavigationOpened ? 'is-navigation-opened' : ''"
      @click="onClick"
    >
      <div class="top-bar">
        <div class="logo">
          <router-link :to="{ name: 'Home' }" @click.native="closeNavigation()">
            <img
              alt="Radio Punctum Logo"
              src="./assets/radio-punctum-logo.svg"
            />
          </router-link>
        </div>

        <div class="audio-player">
          <StreamPlayer ref="streamPlayer" />
        </div>

        <Navigation ref="navigation" />
      </div>

      <section>
        <router-view :key="$route.fullPath" />
      </section>

      <FooterComponent />

      <ArchivePlayer v-show="isActiveArchivePlayer()" ref="archivePlayer" />

      <ChatComponent />
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ArchivePlayer from "@/components/AudioPlayer/ArchivePlayer.vue";
import StreamPlayer from "@/components/AudioPlayer/StreamPlayer.vue";
import Navigation from "@/components/Navigation.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import ChatComponent from "@/components/ChatComponent.vue";
import { isMobile, localStorageRead } from "@/helpers.js";

export default {
  components: {
    ChatComponent,
    Navigation,
    FooterComponent,
    ArchivePlayer,
    StreamPlayer
  },
  data() {
    return {
      bottom: 0,
      volume: 100
    };
  },
  mounted: async function() {
    const archivePlayer = this.$root.$children[0].$refs.archivePlayer;
    const item = localStorageRead("archivePlayerItem");
    if (item) {
      await archivePlayer.loadItem({ item: item, play: false });
      const currentTime = localStorage.archivePlayerTime;
      //archivePlayer.$refs.slider.value = currentTime;
      //      archivePlayer.$refs.archivesource.currentTime = currentTime;
      archivePlayer.initTime(currentTime);
    }
  },
  computed: {
    classNames: function() {
      let cls = [this.$route.name ? this.$route.name.toLowerCase() : ""];
      if (this.isMobile()) {
        cls.push("mobile");
      }
      return cls.join(" ");
    },
    ...mapState({
      navigation: state => state.navigation
    }),
    ...mapState({
      activeArchivePlayer: state => state.archivePlayer.active
    }),
    ...mapState({
      archivePlayer: state => state.archivePlayer
    }),
    ...mapGetters("audioPlayer", ["getPlayingSrc"])
  },
  watch: {
    getPlayingSrc: function(newSrc, oldSrc) {
      if (newSrc !== oldSrc) {
        this.$root.$emit("audioplayer_src_changed", newSrc.src);
      }
    },
    activeArchivePlayer: function(newVal) {
      this.bottom = newVal ? 80 : 0;
    }
  },
  created() {},
  destroyed() {},
  methods: {
    isMobile,
    ...mapActions("navigation", ["closeNavigation"]),
    ...mapGetters("archivePlayer", ["isActiveArchivePlayer"]),

    onClick: function(e) {
      this.$root.$emit("click_event", e);
    }
  }
};
</script>

<style lang="scss">
@import "./variables";
@import "./mixins";

.home {
  @media (min-width: $screen-md-min) {
    background: $bg-color 0% 0% no-repeat padding-box
      url("/images/footer_fotka_tendance.jpg");
    background-position: bottom right;
    background-size: contain;
  }
}

.about {
  background-image: url("/images/fotka_about.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;

  &.mobile {
    background-image: url("/images/fotka_about_mobile.jpg");
  }
}

main {
  display: flex;
  min-height: 100vh;
  padding-top: $navigation-height-xs;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $screen-xxxs-min) {
    padding-top: $navigation-height-xxxs;
  }

  @media (min-width: $screen-sm-min) {
    padding-top: $navigation-height;
  }

  .top-bar {
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    align-items: center;
    background: $navigation-bg;
    box-shadow: -20px 0px 20px 20px rgba(0, 0, 0, 0.15);
    z-index: 21;
  }

  .logo {
    padding: $base-unit;
    flex-shrink: 0;

    &:hover {
      cursor: pointer;
    }

    img {
      display: block;
      height: $logo-height-xs;
      width: $logo-height-xs;

      @media (max-width: $screen-xxxs-min) {
        height: $logo-height-xxxs;
        width: $logo-height-xxxs;
      }

      @media (min-width: $screen-sm-min) {
        height: $logo-height;
        width: $logo-height;
      }
    }
  }

  &.is-navigation-opened {
    overflow: hidden;
  }
}

.audio-player {
  position: relative;
  flex: 4 1 auto;

  @media (min-width: $screen-lg-min) {
    max-width: 500px;
  }
}

.btn-to-top {
  @include button-reset;
}
</style>
