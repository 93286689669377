<template id="title-template">
  <svg
    heigth="auto"
    width="100%"
    style="overflow: visible; text-decoration: none;"
    ref="svgEl"
    preserveAspectRatio="xMidYMin"
  >
    <text x="0" y="0" class="svg-title">
      <!-- eslint-disable -->
      <tspan
        v-for="(text, idx) in splittedLines"
        :class="class_names"
        x="0"
        :dy="dy ? dy : '1em'"
        v-bind:key="idx"
        letter-spacing="5"
      >{{ text }}</tspan>
    </text>
  </svg>
</template>

<script>
import { getLinesForTitle, isMobile } from "@/helpers.js";

export default {
  name: "OutlinedTitle",
  template: "#title-template",
  props: ["lines", "class_names", "dy"],
  computed: {
    splittedLines: function() {
      return getLinesForTitle(this.lines);
    }
  },
  mounted() {
    this.resizeSVG();
  },
  created() {
    window.addEventListener("resize", this.resizeSVG);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeSVG);
  },
  methods: {
    isMobile,
    resizeSVG: function() {
      const svg = this.$refs.svgEl;
      // Get the bounds of the SVG content
      const bbox = svg.getBBox();
      // Update the width and height using the size of the contents
      svg.setAttribute("width", bbox.width);
      svg.setAttribute("height", bbox.height);
      //console.log('resizeSVG text', svg.textContent);
      //console.log('resizeSVG x', bbox.x, bbox.width);
      //console.log('resizeSVG y', bbox.y, bbox.height);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../variables";

.svg-title-box {
  display: block;
  margin: 0 auto;
}

.svg-title {
  font-family: $title-font;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1em;
  text-decoration: none;

  .outline {
    stroke-width: 1px;
  }

  .black-stroke {
    stroke: black;
  }

  .green-stroke {
    stroke: $color-secondary;
  }

  .white-stroke {
    stroke: white;
  }

  .transp {
    fill: transparent;
  }

  .white {
    fill: #fff;
  }

  .black {
    fill: #000;
  }
}
</style>
