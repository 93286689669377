import Vue from "vue";
import VueResource from "vue-resource";
import { deviceType, getApiUrl } from "@/helpers.js";

Vue.use(VueResource);
Vue.http.options.credentials = true;

const apiUrl = getApiUrl();
const customActions = {
  homePageContent: {
    method: "GET",
    url: apiUrl + "content/home/"
  },
  upcoming: {
    method: "GET",
    url: apiUrl + "programme/upcomming/"
  },
  calendar: {
    method: "GET",
    url: apiUrl + "programme/calendar{/months}{/page}/"
  },
  program: {
    method: "GET",
    url: apiUrl + "programme/upcomming{/days}/"
  },
  playingnow: {
    method: "GET",
    url: apiUrl + "programme/playingnow/"
  },
  shows: {
    method: "GET",
    url: apiUrl + "shows/{?enabled,closed,page,ordering}"
  },
  showDetail: {
    method: "GET",
    url: apiUrl + "shows{/slug}/"
  },
  events: {
    method: "GET",
    url: apiUrl + "events/"
  },
  eventDetail: {
    method: "GET",
    url: apiUrl + "events{/slug}/"
  },
  archiveDetail: {
    method: "GET",
    url: apiUrl + "archive{/recording}/{?tm}"
  },
  archive: {
    method: "GET",
    url: apiUrl + "archive/{?genres,artists}"
  },
  artistsArchive: {
    method: "GET",
    url: apiUrl + "artists-archive/{?artists,page}"
  },
  genresArchive: {
    method: "GET",
    url: apiUrl + "genres-archive/{?genres,page}"
  },
  programArchive: {
    method: "GET",
    url: apiUrl + "program-archive/{?page}"
  },
  recordings: {
    method: "GET",
    url: apiUrl + "shows{/slug}/recordings/{?page}"
  },
  search: {
    method: "GET",
    url: apiUrl + "search/{?query}"
  },
  archiveSearch: {
    method: "GET",
    url: apiUrl + "archive-search/{?query}"
  },
  getPlaylist: {
    method: "GET",
    url: apiUrl + "playlist{/id}/"
  },
  saveSelection: {
    method: "POST",
    url: apiUrl + "playlist/"
  }
};

const resource = Vue.resource(apiUrl, {}, customActions);

export default {
  getHomePageContent() {
    //var nc = new Date().getTime();
    return resource
      .homePageContent({ device: deviceType() })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getUpcoming() {
    return resource
      .upcoming({ device: deviceType() })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getCalendar({ months, page }) {
    //var nc = new Date().getTime();
    return resource
      .calendar({
        months: months,
        //_nc: nc,
        //device: deviceType(),
        page: page
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getProgram({ days }) {
    //var nc = new Date().getTime();
    return resource
      .program({
        days: days,
        //  _nc: nc,
        device: deviceType()
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getPlayingNow() {
    var t = Math.round(new Date().getTime() / 1000);
    return resource
      .playingnow({
        _nc: t,
        device: deviceType()
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getShows(page, enabled, closed, pagesize, ordering) {
    let args = {
      pagesize: pagesize ? pagesize : 8,
      page: page,
      enabled: enabled,
      closed: closed
    };
    if (ordering === "asc") {
      args.ordering = "name";
    } else if (ordering === "desc") {
      args.ordering = "-name";
    }
    return resource
      .shows(args)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getShowDetail(slug) {
    //var nc = new Date().getTime();
    return resource
      .showDetail({
        //_nc: nc,
        device: deviceType(),
        slug: slug
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getEvents() {
    return resource
      .events() //{ device: deviceType() })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getEventDetail(slug) {
    return resource
      .eventDetail({
        device: deviceType(),
        slug: slug
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getArchiveDetail(searchParams) {
    return resource
      .archiveDetail({
        recording: searchParams.recording,
        tm: searchParams.tm
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getRecordings(slug, page) {
    return resource
      .recordings({
        slug: slug,
        page: page
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getArchive(searchParams) {
    return resource
      .archive({
        genres: searchParams.genres,
        artists: searchParams.artists,
        device: deviceType()
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getArtistsArchive(artists, page) {
    return resource
      .artistsArchive({
        artists: artists,
        device: deviceType(),
        page: page
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getGenresArchive(genres, page) {
    return resource
      .genresArchive({
        genres: genres,
        device: deviceType(),
        page: page
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getProgramArchive(page) {
    return resource
      .programArchive({
        device: deviceType(),
        page: page
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getSearchResults(query) {
    // var nc = new Date().getTime();
    return resource
      .search({
        query: query,
        device: deviceType()
        // _nc: nc
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getArchiveSearchResults(query, page) {
    // var nc = new Date().getTime();
    return resource
      .archiveSearch({
        query: query,
        page: page,
        device: deviceType()
        // _nc: nc
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getPlaylist(id) {
    // var nc = new Date().getTime();
    return resource
      .getPlaylist({
        //  _nc: nc,
        id: id
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  saveSelection(data, selectionId, csrftoken) {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRFToken": csrftoken
      },
      withCredentials: true
    };
    if (selectionId) {
      let playlistUrl = apiUrl + "playlist/" + selectionId + "/";
      return Vue.http
        .put(playlistUrl, data, config)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error));
    } else {
      let playlistUrl = apiUrl + "playlist/";
      return Vue.http
        .post(playlistUrl, data, config)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error));
    }
  },

  getWaveformData(url) {
    return Vue.http
      .get(url, { credentials: false })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
};
