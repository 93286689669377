const state = {
  items: []
};

const getters = {};

const actions = {
  updateListingItems({ commit }, { items, append }) {
    commit("setListingItems", { items: items, append: append });
  }
};

const mutations = {
  setListingItems(state, { items, append }) {
    if (append) {
      state.items = [...state.items, ...items];
    } else {
      state.items = items;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
