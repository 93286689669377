<template>
  <div class="audio-player-wrapper">
    <div class="audio-player-control-wrapper">
      <slot name="audio-player-control">
        <div class="audio-player-control">
          <span></span>
        </div>
      </slot>
    </div>
    <div class="audio-player-metadata">
      <div class="audio-player-metadata-source">
        <slot name="audio-player-source"></slot>
      </div>
      <div class="audio-player-metadata-title">
        <div class="audio-player-metadata-title-wrapper" ref="titlewrapper">
          <slot name="audio-player-title"></slot>
        </div>
      </div>
    </div>
    <slot name="audio-player-addon"> </slot>
  </div>
</template>

<script>
export default {
  name: "AudioPlayerBase",
  components: {},
  computed: {},
  data: function() {
    return {};
  },
  created() {},
  mounted: function() {
    var titlewrapper = this.$refs.titlewrapper;
    this.registerHandlers(titlewrapper, "AnimationEnd", this.toggleDirection);
    this.$root.$on("play_content_changed", () => {
      this.setScrollingWidth();
    });
  },
  updated() {},
  methods: {
    registerHandlers: function(element, type, callback) {
      var pfx = ["webkit", "moz", "MS", "o", ""];
      for (var p = 0; p < pfx.length; p++) {
        if (!pfx[p]) type = type.toLowerCase();
        element.addEventListener(pfx[p] + type, callback, false);
      }
    },
    toggleDirection: function(e) {
      var titlewrapper = this.$refs.titlewrapper;
      var oldClass, newClass;
      if (e.animationName == "scrollin-left") {
        oldClass = "scroll-title-left";
        newClass = "scroll-title-right";
      } else if (e.animationName == "scrollin-right") {
        oldClass = "scroll-title-right";
        newClass = "scroll-title-left";
      }
      setTimeout(function() {
        titlewrapper.classList.remove(oldClass);
        titlewrapper.classList.add(newClass);
      }, 3000);
    },
    setScrollingWidth: function() {
      this.$nextTick(function() {
        var titlewrapper = this.$refs.titlewrapper;
        if (titlewrapper.offsetParent) {
          // length difference between wrapper and it's content
          var sw = titlewrapper.scrollWidth - titlewrapper.offsetWidth;
          sw = sw < 0 ? 0 : sw;
          // count animation duration value 30 represents velocity (t = s/v)
          var dur = sw / 30;
          //console.log("scroll width:", sw);
          //console.log("velocity:", dur);
          //console.log("parent:", titlewrapper.offsetParent);
          titlewrapper.style.setProperty(
            "--scrollin-width",
            sw < 0 ? 0 : sw + "px"
          );
          titlewrapper.style.setProperty(
            "animation-duration",
            // minimal animation duration 1 second
            (dur < 1 ? 1 : dur) + "s"
          );
          //console.log("classes", titlewrapper.classList);
          if (!titlewrapper.classList.contains("scroll-title-left")) {
            titlewrapper.classList.remove("scroll-title-right");
            setTimeout(function() {
              titlewrapper.classList.add("scroll-title-left");
            }, 2000);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../variables";

@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scrollin-left {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(var(--scrollin-width, 0px) * -1), 0, 0);
  }
}

@keyframes scrollin-right {
  0% {
    transform: translate3d(calc(var(--scrollin-width, 0px) * -1), 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.audio-player-wrapper {
  display: flex;
  height: $navigation-height-xs;
  align-items: center;

  @media (max-width: $screen-xxxs-min) {
    height: $navigation-height-xxxs;
  }

  @media (min-width: $screen-sm-min) {
    height: $navigation-height;
  }
}

.audio-player-metadata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $base-unit/2;
  height: $navigation-height-xs;
  width: 100%;
  font-size: 18px;
  overflow: hidden;

  @media (min-width: $screen-xs-min) {
    height: $navigation-height;
    padding: 0;
    font-size: 95%;
  }

  small {
    font-size: 70%;
  }

  .live-broadcast {
    padding: 1px 5px;
    background-color: $player-live-broadcast-color;
    color: $color-primary;
    border-radius: 2px;
    font-weight: 600;
    font-size: 45%;
    margin-right: $base-unit/2;

    @media (min-width: $screen-sm-min) {
      font-size: 85%;
    }
  }

  .now {
    color: $player-now-color;
    font-size: 14px;

    @media (min-width: $screen-md-min) {
      padding: 0;
      font-size: 20px;
    }
  }

  .audio-player-metadata-title {
    min-width: 150px;
    max-width: 100%;
    width: 100%;
    // margin: 0 $base-unit;
    font-family: $title-font;
    font-weight: lighter;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 3;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: $screen-xs-min) {
      max-width: 195px;
    }

    @media (max-width: $screen-xxs-min) {
      max-width: 160px;
      min-width: 90px;
    }

    @media (max-width: $screen-xxxs-min) {
      max-width: 130px;
    }

    @media (min-width: $screen-xxs-min) and (max-width: $screen-xs-min) {
      min-width: 90px;
      padding-left: 30px;
    }

    @media (min-width: $screen-sm-min) {
      line-height: 2;
      font-size: 15px;
    }

    .audio-player-metadata-title-wrapper {
      width: 100%;
      white-space: nowrap;

      &.scroll-title-right {
        transform: translate3d(0, 0, 0);
        animation: scrollin-right 10s linear 1;
      }

      &.scroll-title-left {
        transform: translate3d(calc(var(--scrollin-width, 0px) * -1), 0, 0);
        animation: scrollin-left 10s linear 1;
      }
    }
  }
  .audio-player-metadata-title::-webkit-scrollbar {
    display: none;
  }

  .audio-player-metadata-source {
    width: 100%;
    // padding: 0 $base-unit;
    flex-shrink: 0;
    line-height: 1.4;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: $screen-xs-min) {
      // padding: 0 $base-unit 0 $base-unit;
      line-height: 1;
    }
  }
}

.audio-player-control {
  box-sizing: border-box;
  cursor: pointer;
  font-family: "punctum";
  text-align: center;
  padding: 0 $base-unit * 2 0 $base-unit * 5;
  font-size: 45px;

  @media (min-width: $screen-sm-min) {
    font-size: 60px;
  }

  @media (max-width: $screen-sm-min) {
    padding: 0 $base-unit 0 $base-unit * 2;
  }

  @media (max-width: $screen-xxxs-min) {
    padding: 0 $base-unit 0 $base-unit * 1.2;
    font-size: 35px;
  }

  &:before {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    content: "\e906";
    color: $color-secondary;
  }

  &.waiting {
    opacity: 0.8;
    animation: zoomin 1s $animation-default infinite;

    &:before {
      content: "\e90d";
    }
  }

  &.playing {
    &:before {
      content: "\e90d";
    }
  }
}
</style>
