import radio from "../api";

const state = {
  all: undefined,
  artists: {
    artists: [],
    data: []
  },
  genres: {
    genres: [],
    data: []
  },
  searchParams: {
    genres: [],
    artists: []
  },
  isLoading: true,
  page: 1,
  nextPage: true,
  artistsPage: 1,
  nextArtistsPage: true,
  activeFilterTab: "genres",
  archiveDetail: null
};

const getters = {
  isInSearchParams: state => filterItem => {
    return state.searchParams[filterItem[0]].includes(filterItem[1]);
  },

  getNameBySlug: state => (key, slug) => {
    const item = state.all[key].filter(item => item.slug == slug)[0];
    return item.name ? item.name : item.slug;
  }
};

const actions = {
  getArtistArchive({ commit }, { artists, clear }) {
    commit("setLoadingState", true);
    if (clear) {
      commit("clearArtistsArchive");
    }
    radio
      .getArtistsArchive(artists, state.artistsPage)
      .then(response => {
        commit("setLoadingState", false);
        commit("setArtistArchive", response);
      })
      .catch(error => {
        error;
      });
  },

  getGenreArchive({ commit }, { genres, clear }) {
    commit("setLoadingState", true);
    if (clear) {
      commit("clearGenreArchive");
    }
    radio
      .getGenresArchive(genres, state.page)
      .then(response => {
        commit("setLoadingState", false);
        commit("setGenreArchive", response);
      })
      .catch(error => {
        error;
      });
  },

  getArchive({ commit, state }) {
    commit("setLoadingState", true);
    radio
      .getArchive(state.searchParams)
      .then(response => {
        commit("setLoadingState", false);
        commit("setArchive", response);
      })
      .catch(error => {
        error;
      });
  },

  loadArchiveDetail({ commit }, recording, tm) {
    commit("setLoadingState", true);
    radio
      .getArchiveDetail({ recording: recording, tm: tm })
      .then(response => {
        commit("setLoadingState", false);
        commit("setArchiveDetail", response);
      })
      .catch(error => {
        error;
      });
  },

  updateSearchParams({ commit, dispatch }, filterItem) {
    commit("setSearchParams", filterItem);
    // TODO: watch for searchParams changes and call getArchive
    dispatch("getArchive");
  },

  updateActiveFilterTab({ commit }, value) {
    commit("setActiveFilterTab", value);
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setGenreArchive(state, resultData) {
    state.page++;
    state.nextPage = resultData.next;
    state.genres.data = [...state.genres.data, ...resultData.data];
    state.genres.genres = [...state.genres.genres, ...resultData.genres];
  },

  clearGenreArchive(state) {
    state.page = 1;
    state.genres = { genres: [], data: [] };
    state.nextPage = true;
  },

  setArtistArchive(state, resultData) {
    state.artistsPage++;
    state.nextArtistsPage = resultData.next;
    state.artists.data = [...state.artists.data, ...resultData.data];
    state.artists.artists = [...state.artists.artists, ...resultData.artists];
  },

  clearArtistsArchive(state) {
    state.artistsPage = 1;
    state.artists = { artists: [], data: [] };
    state.nextArtistsPage = true;
  },

  setArchive(state, resultData) {
    state.all = resultData;
  },

  setArchiveDetail(state, resultData) {
    state.archiveDetail = resultData.data;
  },

  setSearchParams(state, filterItem) {
    const arrayRemove = function(arr, value) {
      return arr.filter(function(ele) {
        return ele != value;
      });
    };
    if (state.searchParams[filterItem[0]].includes(filterItem[1])) {
      state.searchParams[filterItem[0]] = arrayRemove(
        state.searchParams[filterItem[0]],
        filterItem[1]
      );
    } else state.searchParams[filterItem[0]].push(filterItem[1]);
  },

  setActiveFilterTab(state, value) {
    state.activeFilterTab = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
