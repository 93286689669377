<script>
import { autoLink } from "@/helpers.js";

export default {
  name: "IrcMessage",
  template: "#irc-message-component-template",
  components: {},
  props: ["msg"],
  computed: {},
  data: function() {
    return {
      query: null
    };
  },
  created() {},
  methods: {
    autoLink: function(str) {
      if (str.length < 1) return "";

      let newStr = "";

      newStr = autoLink.call(str, {
        callback: function(url) {
          // todo: this is called on input field keyup. why?
          /*      if (/\.(gif|png|jpe?g|webp|avif)$/i.test(url)) {
            return (
              '<a class="msgimg" href="' +
              url +
              '" data-external="true" target="_blank" rel="nofollow noopener"><img src="' +
              url +
              '" style="width: 100%;"></a>'
            );
          } else if (/(radiopunctum\.cz|localhost)/i.test(url)) {
            return (
              '<a href="' +
              url +
              '" target="_blank">Radiopunctum <span class="icon-external-link"></span></a>'
            );
          } else { */
          return (
            '<a href="' +
            url +
            '" data-external="true" target="_blank" rel="nofollow noopener">' +
            url +
            ' <span class="icon-external-link"></a>'
          );
          //}
        }
      });

      const replaceWWW = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      newStr = newStr.replace(
        replaceWWW,
        '$1<a href="http://$2" data-external="true" target="_blank" rel="nofollow noopener">$2</a>'
      );

      const replaceMailto = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.(?:[a-zA-Z]{2,6})+)+)/gim;
      newStr = newStr.replace(
        replaceMailto,
        '<a class="mailto" href="mailto:$1">$1</a>'
      );

      return newStr;
    },
    msgType: function(message) {
      // console.log("msg type", message);
      if (message.prefix) {
        // console.error(message, message.prefix.nick,"@"+this.nick);
      }

      if (
        message.prefix &&
        !message.prefix.isServer &&
        (message.prefix.nick == this.nick ||
          message.prefix.nick == "@" + this.nick)
      ) {
        // this is a normal PRIVMSG sent by you
        return "self";
      }

      const addressedToYou = new RegExp("@" + this.nick + "\\b");
      if (addressedToYou.test(message.params.join(" "))) {
        // this is a PRIVMSG mentioning you
        return "mention";
      }

      if (message.command && message.command.toUpperCase() == "PRIVMSG") {
        // this is a PRIVMSG
        return "pm";
      }

      return "";
    },
    formatMsgTime: function(msgTime) {
      const today = new Date().setHours(0, 0, 0, 0);
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.setHours(0, 0, 0, 0);
      const msgDate = new Date(msgTime).setHours(0, 0, 0, 0);
      if (msgDate == today) {
        return (
          "at " +
          new Date(msgTime).toLocaleTimeString("en-US", {
            hour12: true,
            timeStyle: "short"
          })
        );
      } else if (msgDate == yesterday) {
        return (
          "Yesterday at " +
          new Date(msgTime).toLocaleTimeString("en-US", {
            hour12: true,
            timeStyle: "short"
          })
        );
      } else {
        return new Date(msgTime).toLocaleString("en-US", {
          hour12: true,
          dateStyle: "long",
          timeStyle: "short"
        });
      }
    }
  }
};
</script>

<template id="irc-message-component-template">
  <div
    v-bind:class="[msg.command == 'ERROR' ? 'error' : '', msgType(msg)]"
    class="message"
  >
    <span class="prefix"
      >{{
        msg.prefix
          ? !msg.prefix.isServer
            ? msg.prefix.nick
            : msg.prefix.host
            ? msg.prefix.host
            : "Server"
          : "Server"
      }}
      <span v-if="msg.tags.time" class="message-time">
        {{ formatMsgTime(msg.tags.time) }}
      </span>
    </span>
    <span
      class="command"
      v-if="msg.command != 'PRIVMSG'"
      v-bind:title="msg.commandString ? msg.commandString : ''"
      >{{ msg.command }}
    </span>
    <!--span class="target" v-bind:class="msg.command.toUpperCase() == 'PRIVMSG' ? 'hidden' : ''">target {{ msg.params[0] }}</span -->
    <span
      class="params"
      v-html="autoLink(msg.params.slice(1).join(' '))"
    ></span>
  </div>
</template>

<style scoped lang="scss">
@import "../../variables";
.message {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  margin-top: -4px;
  padding: 0 0 5px;
  display: flex;
  line-height: 1.2em;
  flex-direction: column;
  width: 100%;
  .prefix {
    color: $color-secondary;
    .message-time {
      color: grey;
      font-size: 70%;
    }
  }
  * {
    overflow-wrap: anywhere;
  }
}
</style>
