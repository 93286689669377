const state = {
  pageTitle: null,
  playingTitle: null
};

const getters = {};

const actions = {};

const mutations = {
  setPageTitle(state, title) {
    state.pageTitle = title;
  },
  setPlayingTitle(state, title) {
    state.playingTitle = title;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
