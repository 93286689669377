const state = {
  isJoined: false
};

const getters = {
  isChatJoined: function(state) {
    return state.isJoined;
  }
};

const mutations = {
  setJoinedState(state, bool) {
    console.log("setJoinedState", bool);
    state.isJoined = bool;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
