import radio from "../api";

function getNextMondayOrThursday(date = new Date()) {
  const dateCopy = new Date(date.getTime());
  const dow = dateCopy.getDay();
  if (dow > 1 && dow < 5) {
    return new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 4) % 7 || 7)
      )
    );
  } else {
    return new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7)
      )
    );
  }
}

const today = getNextMondayOrThursday();
const show18 = new Date(today.getTime());
show18.setHours(18, 0, 0);
const show19 = new Date(today.getTime());
show19.setHours(19, 0, 0);
const show20 = new Date(today.getTime());
show20.setHours(20, 0, 0);
const show21 = new Date(today.getTime());
show21.setHours(21, 0, 0);

const state = {
  isLoading: false,
  program: {
    data: [
      {
        date: today.toDateString(),
        weekday: "▢▢▢▢▢▢▢▢▢▢",
        location: null,
        shows: [
          {
            start: show18.toString(),
            location: null,
            title: "▢▢▢▢▢▢▢▢▢▢",
            slug: ".",
            presenters: [],
            external_event: false
          },
          {
            start: show19.toString(),
            location: null,
            title: "▢▢▢▢▢▢▢▢▢▢",
            slug: ".",
            presenters: [],
            external_event: false
          },
          {
            start: show20.toString(),
            location: null,
            title: "▢▢▢▢▢▢▢▢▢▢",
            slug: ".",
            presenters: [],
            external_event: false
          },
          {
            start: show21.toString(),
            location: null,
            title: "▢▢▢▢▢▢▢▢▢▢",
            slug: ".",
            presenters: [{ name: "▢▢▢▢▢▢▢▢▢▢" }],
            external_event: false
          }
        ]
      }
    ]
  },
  programIsLoading: false,
  all: {
    data: {
      title: "Home",
      banners: [
        {
          title: "███████ █████",
          image: "",
          url: "",
          open_in_new_window: true,
          image_small: ""
        },
        {
          title: "███████ ████████",
          image: "",
          url: "",
          open_in_new_window: true,
          image_small: ""
        },
        {
          title: "███████ ████████",
          image: "",
          url: "",
          open_in_new_window: true,
          image_small: ""
        }
      ],
      latest: [
        {
          name: "███████████",
          slug: ".",
          presenters: [{ name: "███████████" }],
          tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
        },
        {
          name: "███████████",
          slug: ".",
          presenters: [{ name: "███████████" }],
          tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
        },
        {
          name: "███████████",
          slug: ".",
          presenters: [{ name: "███████████" }],
          tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
        },
        {
          name: "███████████",
          slug: ".",
          presenters: [{ name: "███████████" }],
          tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
        }
      ]
    }
  }
};

const getters = {
  getUpcoming: state => {
    //return state.all.data;
    if (state.program.data) {
      return state.program.data;
      //return state.all.data.filter(show => !show.closed);
    }
  },
  getLatestContent: state => {
    //return state.all.data;
    if (state.all.data.latest) {
      return state.all.data.latest;
      //return state.all.data.filter(show => !show.closed);
    }
  }
};

const actions = {
  loadHomePageContent({ commit, state }) {
    if (!state.all.data.shows) {
      commit("setLoadingState", true);
      radio
        .getHomePageContent()
        .then(response => {
          commit("setLoadingState", false);
          commit("setHomePageContent", response);
        })
        .catch(error => {
          error;
        });
    }
  },
  loadHomePageProgram({ commit }, days) {
    commit("setProgramLoadingState", true);
    radio
      .getProgram({ days: days })
      .then(response => {
        commit("setProgramLoadingState", false);
        commit("setHomePageProgram", response);
      })
      .catch(error => {
        error;
      });
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setProgramLoadingState(state, bool) {
    state.programIsLoading = bool;
  },

  setHomePageContent(state, resultData) {
    state.all = resultData;
  },

  setHomePageProgram(state, resultData) {
    state.program = resultData;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
