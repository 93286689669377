import radio from "../api";

const state = {
  all: [],
  recordingsCount: 0,
  isLoading: false,
  page: 1,
  nextPage: true
};

const getters = {
  getProgramArchive: state => {
    return state.all;
  },
  getRecordingsCount: state => {
    return state.recordingsCount;
  }
};

const actions = {
  loadProgramArchive({ commit, state }) {
    commit("setLoadingState", true);
    commit("setProgramArchiveLoadDisabled");
    radio.getProgramArchive(state.page).then(response => {
      commit("setLoadingState", false);
      commit("setProgramArchive", response);
    });
  },
  updateRecordingsCount({ commit }, count) {
    commit("setRecordingsCount", count);
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setProgramArchive(state, resultData) {
    state.all = [...state.all, ...resultData.data];
    state.page++;
    state.nextPage = resultData.next;
  },

  setProgramArchiveLoadDisabled(state) {
    state.nextPage = false;
  },

  setRecordingsCount(state, count) {
    state.recordingsCount += count;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
