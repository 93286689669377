<template id="search-component-template">
  <div class="search-input">
    <form v-on:submit.prevent="showResults">
      <input
        ref="querysource"
        id="querysource"
        class="search-input-phrase"
        name="query"
        v-model="query"
        type="search"
        autocomplete="off"
        v-on:keyup.esc="close"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SearchInput",
  template: "#search-component-template",
  components: {},
  props: ["phrase"],
  computed: {
    ...mapState({
      search: state => state.search,
      navigation: state => state.navigation
    })
  },
  data: function() {
    return {
      query: null
    };
  },
  created() {},
  mounted: function() {
    if (["Search", "SearchResults"].includes(this.$route.name)) {
      this.getSearchResults(this.query);
    }
    //    this.$root.$on("search_query_updated", () => {
    //      this.query = this.search.query;
    //    });
    this.$root.$on("search_input_opened", () => {
      this.$refs.querysource.focus();
    });
  },
  methods: {
    showResults: function() {
      this.$router.push({
        name: "SearchResults",
        params: { query: this.query }
      });
    },
    closeIfEmpty: function() {
      if (!this.query) {
        this.closeSearch();
        this.$root.$emit("search_input_closed");
      }
    },
    close: function() {
      this.closeSearch();
      this.$root.$emit("search_input_closed");
    },
    ...mapActions("navigation", ["closeSearch"]),
    ...mapActions("search", ["getSearchResults"])
  }
};
</script>

<style scoped lang="scss">
@import "../../variables";

.search-input {
  .search-results {
    background-color: $navigation-bg;
  }

  .loading-wrapper {
    position: absolute;
    top: 70px;
    width: 100%;
    margin-right: 10px;
    margin-left: -20px;
    background-color: $navigation-bg;
    opacity: 0.5;
  }
}
</style>
