import radio from "../api";

const state = {
  events: { count: 0 },
  shows: { count: 0 },
  tags: { count: 0 },
  artists: { count: 0 },
  archive: [],
  count: 0,
  query: null,
  isLoading: false,
  isArchiveLoading: false,
  archivePage: 1,
  nextArchivePage: true
};

const getters = {};

const actions = {
  getSearchResults({ commit }, query) {
    if (!query) {
      return;
    }
    commit("setSearchQuery", query);
    commit("setLoadingState", true);
    radio.getSearchResults(query).then(response => {
      commit("setLoadingState", false);
      commit("setSearchResults", response);
    });
  },
  getArchiveSearchResults({ commit, state }, { query, clear }) {
    commit("setSearchQuery", query);
    commit("setArchiveLoadingState", true);
    if (clear) {
      commit("clearArchiveSearchResults");
    }
    radio.getArchiveSearchResults(query, state.archivePage).then(response => {
      commit("setArchiveLoadingState", false);
      commit("setArchiveSearchResults", response);
    });
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setArchiveLoadingState(state, bool) {
    state.isArchiveLoading = bool;
  },

  setSearchQuery(state, query) {
    state.query = query;
  },

  setSearchResults(state, resultData) {
    state.artists = resultData.data.artists;
    state.tags = resultData.data.tags;
    state.shows = resultData.data.shows;
    state.count = resultData.count;
  },

  clearArchiveSearchResults(state) {
    state.archivePage = 1;
    state.archive = [];
    state.nextArchivePage = true;
  },

  setArchiveSearchResults(state, resultData) {
    state.archivePage++;
    state.archive.push(...resultData.data);
    state.nextArchivePage = resultData.next;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
