<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { Parser } from "@/ircParser.js";
import { objectToBase64 } from "@/helpers.js";
import IrcMessage from "@/components/Chat/IrcMessage.vue";
import InternalMessage from "@/components/Chat/InternalMessage.vue";
import { isMobile } from "@/helpers.js";

const defaultChannel = "#radio";
const serverUrl = "irc.radiopunctum.cz";
const serverPort = "8080";
const seed = Math.floor(Math.random() * 100000 + 1);

export default {
  name: "ChatComponent",
  template: "#chat-component-template",
  components: {
    InternalMessage,
    IrcMessage
  },
  computed: {
    parser: function() {
      return new Parser();
    },
    ...mapState({
      archivePlayer: state => state.archivePlayer
    })
  },
  created() {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      this.username = user.username;
      this.realname = user.realname;
      this.nick = user.nickname;
    }
  },
  mounted() {
    this.$root.$on("play_content_changed", () => {
      const classes = [];
      if (this.chatOpened) {
        classes.push("chat-opened");
      }
      if (this.archivePlayer.active) {
        classes.push("archive-player-active");
      }
      this.chatOpenedClass = classes.join(" ");
    });
    this.$root.$on("archiveplayer_play", () => {
      const classes = ["archive-player-active"];
      if (this.chatOpened) {
        classes.push("chat-opened");
      }
      this.chatOpenedClass = classes.join(" ");
    });
    this.$root.$on("archiveplayer_closed", () => {
      const classes = [];
      if (this.chatOpened) {
        classes.push("chat-opened");
      }
      this.chatOpenedClass = classes.join(" ");
    });
    this.$root.$on("internal_message_created", (msg, url) => {
      if (this.ws) {
        this.sendInternalMessage(msg, url);
      }
    });
    this.$root.$on("internal_message_clicked", () => {
      if (isMobile()) {
        this.toggleChatWindow();
      }
    });
  },
  data() {
    return {
      autoJoin: [defaultChannel],
      connected: false,
      chatOpened: false,
      showChatMenu: false,
      inputLog: [],
      inputLogIndex: null,
      input: "",
      messages: [],
      users: [],
      username: "guest_" + seed,
      nick: "guest_" + seed,
      lastLegalNick: null,
      realname: "Unknown",
      lockInput: false,
      messageLog: [],
      ws: null,
      visible: true,
      chatConnectedClass: "",
      chatOpenedClass: "",
      toastedOptions: {
        className: ["toasted", "toasted-chat-event", "default"]
      }
    };
  },
  methods: {
    sendInternalMessage: function(msg, url) {
      //msg.internal = true;
      //msg.prefix = msg.prefix ? msg.prefix : {};
      //msg.prefix.nick = this.nick;
      //this.messages.push(msg);
      //const text = url + " data:application/json;base64," + objectToBase64(msg);
      const tags = {
        "+cue-point": "object:base64," + objectToBase64(msg)
      };
      this.sendMessage(url, defaultChannel, tags);
      this.addMessage(url, defaultChannel, tags);
      this.scrollToLastMessage();
    },
    isDisabled: function(input) {
      if (this[input]) {
        if (input == "nick") {
          if (this[input].indexOf(" ") > -1) {
            return true;
          } else if (this[input].length < 2) {
            return true;
          } else {
            const regex = RegExp(/^[a-zA-Z][a-zA-Z0-9[\]{}_-]{2,12}$/);
            return !regex.test(this[input]);
          }
        }
        return this[input].length == 0;
      }
      return true;
    },
    toggleChatWindow: function() {
      const classes = [];
      if (this.isActiveArchivePlayer()) {
        classes.push("archive-player-active");
      }
      if (this.chatOpened) {
        this.chatOpened = false;
      } else {
        this.chatOpened = true;
        classes.push("chat-opened");
      }
      this.chatOpenedClass = classes.join(" ");
      //console.log("toggleChatWindow", this.chatOpened);
    },
    toggleConnection: function() {
      if (this.ws === null) {
        this.connect();
      } else {
        this.disconnect();
      }
      //console.log("toggleConnection", this.connected);
    },
    connect: function() {
      const conn = "wss://" + serverUrl + ":" + serverPort;
      this.ws = new WebSocket(conn);

      //console.log("connecting to", conn);

      this.ws.onopen = this.onOpen;
      this.ws.onerror = this.onError;
      this.ws.onclose = this.onClose;
      this.ws.onmessage = this.onMessage;
    },
    disconnect: function() {
      this.sendMessage("/QUIT");
      this.ws.send("QUIT");
      // if the QUIT command works, no need to manually call close
      // this.ws.close();
      this.setJoinedState(false);
    },
    onOpen: function(event) {
      //console.log("INFO: Socket Opened", event);
      event;
      this.connected = true;
      this.messages = [];
      this.setJoinedState(true);
      this.chatConnectedClass = "chat-connected";

      //this.lockInput = true; // prevent changes to user data

      this.ws.send("CAP LS 302");
      this.ws.send("NICK " + this.nick);
      this.ws.send("USER " + this.username + " 0 * :" + this.realname);

      // todo: do these as a reply to the list sent by the server
      // https://unitedchat.org/ircv3
      this.ws.send("CAP REQ :message-tags");
      this.ws.send("CAP REQ :server-time");
      this.ws.send("CAP REQ :echo-message");
      this.ws.send("CAP END");
    },
    onError: function(error) {
      //console.log("ERR: ", error);
      error;
      this.setJoinedState(false);
    },
    onClose: function(event) {
      //console.log("INFO: Socket Closed", event);
      event;

      this.ws.onopen = function() {};
      this.ws.onerror = function() {};
      this.ws.onclose = function() {};
      this.ws.onmessage = function() {};

      this.ws = null;
      this.connected = false;
      this.chatConnectedClass = "";
      this.setJoinedState(false);
      //this.lockInput = false;

      // app.messageWindow.addMessage("closed the connection.", channel);
      this.addRawCommand(
        ":" +
          this.nick +
          "!" +
          this.username +
          "@127.0.0.1 STATUS " +
          defaultChannel +
          " :closed the connection."
      );
    },
    onMessage: function(event) {
      //console.log("RECV: ", event.data);

      const data = event.detail ? event.detail : event.data;

      const msg = this.parser.parse(data);
      if (!msg) return;
      //console.log("onMessage", msg);
      //console.log("onMessage", msg.tags.time ? new Date(msg.tags.time).toLocaleTimeString("en-US", {hour12: false, timeStyle: "short"}) : "Missing datetime");
      this.messageLog.push(msg);
      // app.demo.messages.push(msg);

      //console.info("cmd", msg.command, msg);

      // handle ping/pong
      if (msg.command && msg.command.toUpperCase() == "PING") {
        const messageParts = data.split(" ");
        // console.log("replying with:", "PONG "+messageParts[1]);
        this.ws.send("PONG " + messageParts[1]);
        return;
      }

      if (msg.prefix && msg.prefix.isServer && msg.prefix.host == serverUrl) {
        //console.warn("server message:", event.data);
      }

      if (msg.command == "001") {
        // if the login is accepted, save user data
        this.lastLegalNick = this.nick;
        this.save();
      }

      if (msg.command == "376" || msg.command == "422") {
        // message of the day, request initial LIST
        //this.ws.send("LIST");

        // auto-join channels, if specified
        if (this.autoJoin.length > 0) {
          this.ws.send("JOIN " + this.autoJoin.join(","));
        }
      }

      if (msg.command == "321") {
        // list items ("channels"), begin list
        //app.channelList.clear();
      }

      if (msg.command == "322") {
        // list items ("channels"), add to list
        console.warn("add channel to list", msg.params);
        //app.channelList.channels.push(msg.params[1]);
      }

      if (msg.command == "323") {
        // sort channel display after building list
        //if (app.channelList.channels.length > 0) {
        //	app.channelList.channels.sort();
        //}
      }

      if (msg.command == "433") {
        // nickname is in use
        if (this.lastLegalNick) {
          this.nick = this.lastLegalNick;
          //console.log("reverted to last legal nick");
        } else {
          // set a random nickname
          const seed = Math.floor(Math.random() * 100000 + 1);
          this.nick = this.nick + "_" + seed;
          this.ws.send("NICK " + this.nick);
          //console.log("set a random nick");
        }
      }

      if (msg.command == "353") {
        // list users in a channel when joining

        const users = msg.params[3].split(" ");
        users.sort();
        //console.info("users in channel", msg.params[2], users);

        //const tab = app.messageWindow.get(msg.params[2]);
        //if (tab.title != "server") {
        //	tab.users = users;
        //}
        this.users = users;

        if (users.length > 0) {
          this.addMessage(
            "Users in this channel are: " + users.join(", "),
            defaultChannel
          );
        } else {
          this.addMessage("No active users in this channel", defaultChannel);
        }
      }

      if (msg.command == "JOIN") {
        if (msg.prefix.nick != this.nick) {
          this.$toasted.show(
            `<span class="icon-login"></span> <span class="nick-name">
                     ${msg.prefix.nick}</span> just joined the chat.`,
            this.toastedOptions
          );
        }

        // join a new channel
        //console.info("new user", msg.prefix.nick, "in channel", msg.params[0]);
        //				const tab = app.messageWindow.get(msg.params[0]);
        //				if (tab) {
        //					if (msg.prefix.nick == this.nick) {
        //						// you joined a channel
        //						app.messageWindow.activate(msg.params[0]);
        //					} else {
        //						// somebody else joined a channel
        //						if(tab.users.indexOf(msg.prefix.nick) === -1) {
        //							tab.users.push(msg.prefix.nick);
        //						}
        //					}
        //				} else {
        //					console.info("created new tab for", msg.params[0]);
        //					app.messageWindow.addTab(msg.params[0], true);
        //				}
        // update the list as well
        //				if (msg.prefix.nick == this.nick) {
        //					if (!app.channelList.channels.includes(msg.params[0])) {
        //						app.channelList.channels.push(msg.params[0]);
        //						app.channelList.channels.sort();
        //					}
        //				}
      }

      if (msg.command == "PART") {
        // part a channel
        if (msg.prefix.nick == this.nick) {
          // you left the channel, close tab
          //					app.messageWindow.removeTab(msg.params[0]);
        } else {
          // somebody else left the channel
          //const tab = app.messageWindow.get(msg.params[0]);
          const index = this.users.indexOf(msg.prefix.nick);
          this.users.splice(index, 1);
          this.users.sort();
        }
      }

      if (msg.command == "NICK") {
        const newNick = msg.params[0];
        console.warn(msg.prefix.nick, "changed their nick to", newNick);

        if (msg.prefix.nick == this.lastLegalNick) {
          // you changed your own name
          if (this.lastLegalNick !== newNick) {
            this.lastLegalNick = newNick;
          }

          this.nick = newNick;

          this.save();
        } else {
          // somebody else changed their name
          // update DMs and channel user lists
          //const activeDM = app.dm.conversations.find(conv => conv.title === msg.prefix.nick);

          //if (activeDM) {
          //	activeDM.title = newNick;
          //}

          if (this.users.length > 0) {
            var index = this.users.indexOf(msg.prefix.nick);
            if (index !== -1) {
              this.users[index] = newNick;
            }
          }
        }
      }

      // handle QUIT, eg part from all channels correctly
      // todo: maybe notify user with open DMs?
      if (msg.command == "QUIT") {
        if (msg.prefix.nick != this.nick) {
          // remove user from all message tabs
          const pos = this.users.indexOf(msg.prefix.nick);
          if (pos !== -1) {
            this.users.splice(pos, 1);
          }
          this.$toasted.show(
            `<span class="icon-logout"></span> <span class="nick-name">
                     ${msg.prefix.nick}</span> left the chat.`,
            this.toastedOptions
          );
          //console.info(msg.prefix.nick, "logged off.");
        }
      }

      // direct messages eg. PRIVMSG to a single user
      if (
        msg.command == "PRIVMSG" &&
        msg.params.length > 0 &&
        !msg.params[0].startsWith("#")
      ) {
        //console.log("received a DM from", msg.prefix.nick, "to", msg.params[0]);
        if (msg.params[0] == this.nick) {
          // this is a message TO you
          this.receivedMessage(msg.prefix.nick, msg);
        } else if (msg.prefix.nick == this.nick) {
          // this is a message BY you
          this.receivedMessage(msg.params[0], msg);
        }
        return;
      }
      //console.log("received", msg);
      this.messages.push(msg);
      this.scrollToLastMessage();
    },
    receivedMessage: function(user, msg) {
      if (user && user.startsWith("@")) user = user.substring(1);

      //console.log("receivedMessage", msg);
      this.messages.push(msg);

      //console.log("receivedMessage", msg);
      this.scrollToLastMessage();
      // trigger event
      const customEvent = new Event("newDM");
      //const root = ref(0);
      const root = this.$el.parentElement; // todo: fix this by using ref() ?

      root.dispatchEvent(customEvent);
    },
    sendMessage: function(text, channel, tags) {
      let message = "";
      if (tags) {
        message = this.constructTags(tags) + " ";
      }
      if (text.startsWith("/") || !channel) {
        message += text.substring(1) + "\r\n";
      } else {
        channel = channel.startsWith("#") ? channel : "#" + channel;
        message += "PRIVMSG " + channel + " :" + text + "\r\n";
        //    const tab = app.messageWindow.get(channel);
        //    if (!tab) {
        //        console.error("You can\"t talk here, you need to join a channel first!");
        //        return;
        //    }
      }
      this.ws.send(message);
    },
    scrollToLastMessage: function() {
      const me = this;
      this.$nextTick(() => {
        const messages = me.$refs.messages;
        if (messages) {
          messages.scrollTop = messages.scrollHeight;
        }
      });
    },
    showMessage: function(msg) {
      const ALLOWED_SERVER_COMMANDS = ["433", "465", "000"];
      const NOT_ALLOWED_CLIENT_COMMANDS = ["JOIN", "QUIT"];
      //console.log("showMessage", msg.command, ALLOWED_COMMANDS.indexOf(msg.command));
      //console.log("showMessage", msg);
      if (
        msg.prefix &&
        (msg.prefix.isServer &&
          ALLOWED_SERVER_COMMANDS.indexOf(msg.command) != -1)
      ) {
        return true;
      } else if (
        msg.prefix &&
        (!msg.prefix.isServer &&
          NOT_ALLOWED_CLIENT_COMMANDS.indexOf(msg.command) == -1)
      ) {
        return true;
      }
      return false;
    },
    constructTags: function(tags) {
      const tagList = [];
      for (const [tag, value] of Object.entries(tags)) {
        console.log(`${tag}: ${value}`);
        tagList.push(`${tag}=${value}`);
      }
      return "@" + tagList.join(";");
    },
    addMessage: function(text, channel, tags) {
      if (!text || !channel) return;
      if (!tags) {
        tags = {};
      }
      tags.time = new Date().toISOString();
      const tagsText = this.constructTags(tags);
      const command =
        tagsText +
        " :" +
        this.nick +
        "!" +
        this.username +
        "@127.0.0.1 PRIVMSG " +
        channel +
        " :" +
        text;
      this.addRawCommand(command);
    },
    addRawCommand: function(str) {
      if (!str) return;

      const customEvent = new Event("customCommand");
      customEvent.data = str + "\r\n";

      this.onMessage(customEvent);
    },
    send: function() {
      // const msgField = document.querySelector("#msg");
      const msgField = this.$refs.msgField;

      // add to input log for recent messages functionality
      this.inputLog.unshift(this.input);
      if (this.inputLog.length > 9) {
        this.inputLog.slice(0, 9);
      }

      // parse the input
      const parsed = this.parser.parse(this.input);
      if (parsed === null) {
        return;
      }
      if (parsed.command.startsWith("/")) {
        parsed.command = parsed.command.substring(1).toUpperCase();
      }

      // smart NAMES command in channels
      if (parsed.command == "NAMES") {
        this.input = "/" + parsed.command + " " + defaultChannel;
      }

      // MSG shorthand
      if (parsed.command == "MSG") {
        this.input = this.input.replace("/MSG", "/PRIVMSG");
      }

      // direct message shorthand with /w
      if (
        parsed.command.toUpperCase() == "W" &&
        parsed.params[0].startsWith("@")
      ) {
        const recipient = parsed.params[0].substring(1);
        const message = parsed.params.slice(1).join(" ");
        this.input = "/PRIVMSG " + recipient + " " + message;
      }

      this.sendMessage(this.input, defaultChannel);
      this.addMessage(this.input, defaultChannel);

      this.input = ""; // reset text input
      this.inputLogIndex = null; // reset history
      if (msgField) {
        msgField.focus();
      }
    },
    keyUp: function(event) {
      // keyboard input history!

      if (event.keyCode == 13) {
        this.send();
      }
      if (event.keyCode == 38) {
        // up arrow
        if (this.inputLog.length > 0) {
          if (this.inputLogIndex !== null) {
            this.inputLogIndex += 1;

            if (!this.inputLog[this.inputLogIndex]) {
              this.inputLogIndex -= 1;
              return;
            }
          } else {
            this.inputLogIndex = 0;
          }

          this.input = this.inputLog[this.inputLogIndex];
        }
      }

      if (event.keyCode == 40) {
        // down arrow
        if (this.inputLog.length > 0) {
          if (this.inputLogIndex !== null) {
            if (this.inputLogIndex === 0) {
              this.input = "";
              this.inputLogIndex = null;
              return;
            }

            this.inputLogIndex -= 1;

            if (!this.inputLog[this.inputLogIndex]) return;

            this.input = this.inputLog[this.inputLogIndex];
          }
        }
      }
    },
    updateNick: function() {
      if (!this.validNick(this.nick)) {
        console.error("nickname contains invalid characters!");
        this.nick = this.lastLegalNick;
        // this.nick = this.nick.replace(/[^a-z0-9\/\[\]\{\}\._-]/gim, '-');
        return;
      }

      this.save(); // this should potentially be done somewhere different

      if (this.connected) {
        this.ws.send("NICK " + this.nick);
      } else {
        this.connect();
      }
    },
    validNick: function(nick) {
      // this is potentially not totally correct
      const regex = RegExp(/^[a-zA-Z][a-zA-Z0-9[\]{}_-]{2,12}$/);
      // const regex = RegExp(/\A[a-z_\-\[\]\\^{}|`][a-z0-9_\-\[\]\\^{}|`]{2,11}\z/);

      return regex.test(nick);
    },
    save: function() {
      const data = {
        username: this.username,
        realname: this.realname,
        nickname: this.nick
      };
      console.warn("saving user data to localstorage");

      this.username = data.username;
      this.realname = data.realname;
      this.nick = data.nickname;

      localStorage.setItem("user", JSON.stringify(data));
    },
    toggleChatMenu: function() {
      this.showChatMenu = !this.showChatMenu;
    },
    ...mapGetters("archivePlayer", ["isActiveArchivePlayer"]),
    ...mapMutations("chat", ["setJoinedState"])
  }
};
</script>

<template id="chat-component-template">
  <div class="chat-window" v-bind:class="chatOpenedClass">
    <div class="header" v-bind:class="chatOpenedClass">
      <div v-if="chatOpened" class="chat-room">Chat room</div>
      <button
        class="chat-toggle-button"
        v-bind:class="chatOpenedClass"
        v-on:click.prevent="toggleChatWindow"
      >
        {{ chatOpened ? "Close" : "Chat now" }}
        <span v-if="!chatOpened" class="icon-arrow-up"></span>
        <span v-if="chatOpened" class="icon-arrow-down"></span>
      </button>
    </div>
    <div class="chat-component-body" v-bind:class="chatOpenedClass">
      <ul class="messages" ref="messages" v-bind:class="chatOpenedClass">
        <template v-for="(msg, idx) in messages">
          <template v-if="showMessage(msg)">
            <li class="message-wrapper" v-bind:key="idx">
              <InternalMessage v-if="msg.internal" :msg="msg" />
              <IrcMessage v-if="!msg.internal" :msg="msg" />
            </li>
          </template>
        </template>
      </ul>
    </div>
    <template v-if="connected">
      <div class="footer" v-bind:class="chatOpenedClass">
        <input
          type="text"
          id="msg"
          class="input-field"
          placeholder="Type your message ..."
          autocomplete="off"
          v-model="input"
          v-on:keyup="keyUp($event)"
          ref="msgField"
        />
        <input
          class="chat-now-button"
          type="submit"
          value="Send"
          :disabled="isDisabled('input')"
          v-on:click.prevent="send"
        />
        <button v-on:click.prevent="disconnect()" class="chat-close-button">
          <i class="icon-logout"></i>
        </button>
      </div>
    </template>
    <template v-else>
      <div class="footer" v-bind:class="chatOpenedClass">
        <input
          type="hidden"
          id="user-name"
          placeholder="your username"
          v-model="username"
          v-bind:readonly="lockInput"
          required
        />
        <input
          type="hidden"
          id="user-realname"
          placeholder="your real name"
          v-model="realname"
          v-bind:readonly="lockInput"
          required
        />
        <label for="user-nickname">nickname</label>
        <input
          type="text"
          id="user-nick"
          class="input-field input-nickname"
          :class="isDisabled('nick') ? 'disabled' : ''"
          placeholder="Insert your nickname ..."
          v-model="nick"
          required
        />
        <input
          class="chat-now-button"
          type="submit"
          value="Join"
          :disabled="isDisabled('nick')"
          v-on:click.prevent="updateNick"
        />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
@import "../variables";
.chat-component-body {
  display: none;
  &.chat-opened {
    display: block;
  }
}
.chat-window,
.chat-window * {
  box-sizing: border-box;
}
.chat-window {
  background: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 200px;
  height: 60px;
  transition: height 0.25s ease-in-out, background 0.25s 0.05s,
    width 0.25s ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;

  &.chat-opened {
    z-index: 3;
    width: 420px;
    background: black;
    height: calc(100dvh - 98px);
    transition: height 0.25s ease-in-out, width 0.25s ease-in-out;
    box-shadow: -4px 4px 4px 4px rgba(0, 0, 0, 0.3);
    &.archive-player-active {
      height: calc(100dvh - 178px);
    }
    @media (max-width: $screen-xs-max) {
      height: calc(100dvh - 80px);
      width: 100%;
      &.archive-player-active {
        height: calc(100dvh - 160px);
      }
    }
  }
  &.archive-player-active {
    bottom: 80px;
    right: 0;
  }
}
.header {
  padding: 0px 8px 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  justify-items: flex-end;
  flex-shrink: 0;
  width: 200px;
  height: 60px;
  position: relative;
  background: transparent;
  &.chat-opened {
    border-style: solid;
    border-color: $color-secondary;
    border-width: 0px 0px 1px 0px;
    width: 420px;
    justify-content: space-between;
    background: black;
    transition: background 0.25s ease-out;
    align-items: center;
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}
.chat-room {
  color: var(--white, #ffffff);
  text-align: left;
  font-family: "MonumentExtended", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap;
}
.messages {
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 420px;
  position: relative;
  height: calc(100dvh - 240px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  &.archive-player-active {
    height: calc(100dvh - 320px);
  }
  @media (max-width: $screen-xs-max) {
    height: calc(100dvh - 220px);
    width: 100%;
    &.archive-player-active {
      height: calc(100dvh - 300px);
    }
  }
}
.messages::-webkit-scrollbar {
  display: none;
}

.message-wrapper {
  padding: 0 8px 0 16px;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  .avatar {
    background: var(--rp-green, #000);
    border-radius: 100px;
    border-style: solid;
    border-color: $color-secondary;
    border-width: 1px;
    flex-shrink: 0;
    width: 51px;
    height: 51px;
    position: relative;
    overflow: hidden;
    &.self {
      background: $color-secondary;
    }
  }
}
.chat-toggle-button {
  position: relative;
  right: 0;
  background: $color-secondary;
  border-radius: 20px;
  border-style: solid;
  border-color: #000000;
  border-width: 2px;
  padding: 4px 14px 4px 14px;
  height: 36px;
  font-family: "MonumentExtended", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  line-height: 1em;
  &:hover {
    background-color: #bafad6;
  }
  &:active {
    background-color: #71d09e;
  }
}
.footer {
  width: 420px;
  border-style: solid;
  border-color: $color-secondary;
  border-width: 1px 0px 0px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  padding: 15px 5px 15px 10px;
  height: 70px;
  display: none;
  &.chat-opened {
    display: flex;
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
  label {
    margin-right: 10px;
  }
}
.input-field {
  border-radius: 4px;
  border-style: solid;
  border-color: $color-secondary;
  border-width: 1px;
  padding: 0px 8px 0px 8px;
  width: 330px;
  height: 36px;
  font-size: 16px;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  outline: 0;
  transition: border-color 200ms;
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
  &.input-nickname::placeholder {
    color: white;
  }
  &.input-nickname:placeholder-shown {
    border: 1px solid crimson;
    background-color: darkred;
  }
  &.disabled {
    border: 1px solid crimson;
    background-color: darkred;
  }
}
.chat-now-button {
  background: $color-secondary;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  font-weight: 700;
  border-radius: 40px;
  border-style: solid;
  border-color: #000000;
  border-width: 4px;
  padding: 4px 12px 4px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: 40px;
  position: relative;
  overflow: hidden;
  color: black;
  &:hover {
    background-color: #bafad6;
  }
  &:active {
    background-color: #71d09e;
  }
  &[disabled="disabled"] {
    background: grey;
  }
}
.chat-close-button {
  background-color: $color-secondary;
  border-radius: 5px;
  border-style: solid;
  border-color: $color-secondary;
  border-width: 1px;
  padding: 5px;
  display: flex;
  align-items: baseline;
  color: black;
  &:hover {
    background-color: #bafad6;
  }
  &:active {
    background-color: #71d09e;
  }
}
</style>
