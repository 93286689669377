<template>
  <AudioPlayerBase ref="audioPlayer">
    <template v-slot:audio-player-control>
      <audio
        ref="streamsource"
        id="source"
        :src="streamUrl"
        preload="none"
      ></audio>
      <div
        ref="streamplayer"
        v-bind:class="streamPlayer.playerState"
        class="audio-player-control"
        id="streamplayer"
        v-on:click="playPause"
      >
        <span></span>
      </div>
    </template>
    <template v-slot:audio-player-source>
      <div v-if="streamPlayer.playingNow.live" class="playing-now-live">
        <small class="live-broadcast">
          <template v-if="streamPlayer.playingNow.reprise">
            REPRISE
          </template>
          <template v-else-if="streamPlayer.playingNow.prerecorded">
            PREMIERE
          </template>
          <template v-else>
            LIVE BROADCAST
          </template>
        </small>
      </div>
      <div v-else class="playing-now-live">
        <span class="now">Now playing</span>
      </div>
    </template>
    <template v-slot:audio-player-title>
      <template v-if="streamPlayer.playingNow.recording_name">
        <router-link
          :to="{
            name: 'ArchiveDetail',
            params: {
              slug: streamPlayer.playingNow.show.slug,
              recording: streamPlayer.playingNow.recording_name
            }
          }"
          class="stream-player-metadata-title"
        >
          {{ nowPlaying }}
        </router-link>
      </template>
      <template v-else>
        {{ nowPlaying }}
      </template>
    </template>
  </AudioPlayerBase>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import AudioPlayerBase from "@/components/AudioPlayer/AudioPlayerBase.vue";
import { getStreamUrl } from "@/helpers.js";
/* eslint-disable */

export default {
  name: "StreamPlayer",
  components: {
    AudioPlayerBase
  },
  computed: {
    streamUrl: function() {
      return getStreamUrl() + 
        "?nocache=" + 
        Math.random()
          .toString()
          .slice(2);
    },
    ...mapState({
      streamPlayer: state => state.streamPlayer
    }),
    nowPlaying: function() {
      return this.getNowPlaying(this.streamPlayer.playingNow);
    },
    ...mapGetters("streamPlayer", ["changedContent"])
  },
  data: function() {
    return {};
  },
  watch: {
    changedContent: function() {
      const streamSource = this.$refs.streamsource;
      const title = this.getNowPlaying(this.streamPlayer.playingNow);
      if (streamSource && !streamSource.paused) {
          this.setPlayingTitle(title);
          document.title = title;
      }
      this.$root.$emit("play_content_changed");
    }
  },
  created() {
    const loadData = () => {
      this.getPlayingNow();
    };

    loadData();

    setInterval(function() {
      loadData();
    }, 10000);
  },
  mounted() {
    var streamSource = this.$refs.streamsource;
    var thisStore = this.$store;
    var streamPlayer = this;

    this.$root.$on("video_playing", () => {
      streamSource.pause();
      thisStore.dispatch("streamPlayer/updatePlayerState", "");
    });

    this.$root.$on("audioplayer_src_changed", src => {
      // console.log("StreamPlayer received axudioplayer_src_changed", src);
      if (streamSource.src !== src) {
        streamSource.pause();
        thisStore.dispatch("streamPlayer/updatePlayerState", "");
      }
    });

    streamSource.onpause = function(e) {
      console.log("AUDIO EVENT: streamSource.onpause", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", "");
    };

    streamSource.onplay = function(e) {
      console.log("AUDIO EVENT: streamSource.onplay ", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", e.type);
      var videoPlayer =
        streamPlayer.$root.$children[0].$children[4].$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.pause();
      }
    };

    streamSource.onplaying = function(e) {
      console.log("AUDIO EVENT: streamSource.onplaying", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", e.type);
    };

    streamSource.onwaiting = function(e) {
      console.log("AUDIO EVENT: streamSource.onwaiting", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", e.type);
    };

    streamSource.onabort = function(e) {
      console.log("AUDIO EVENT: streamSource.onabort", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", "");
      //streamSource.load();
      //streamSource.src = streamPlayer.streamUrl;
    };

    streamSource.onended = function(e) {
      console.log("AUDIO EVENT: streamSource.onended", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", "waiting");
      streamSource.load();
    };

    streamSource.onloadeddata = function(e) {
      console.log("AUDIO EVENT: streamSource.onloadeddata", e);
      streamSource.play();
    };

    streamSource.onstalled = function(e) {
      console.log("AUDIO EVENT: streamSource.onstalled", e);
      //streamSource.load();
    };

    // streamSource.oncanplay = function(e) {
    //   console.log("streamSource.oncanplay happened", e);
    // };

    // streamSource.oncanplaythrough = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.ondurationchange = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onemptied = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onerror = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onloadedmetadata = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onloadstart = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onprogress = function (e) {
    //     console.log("AUDIO EVENT: ", e)
    // };

    // streamSource.onratechange = function() {
    //  console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onseeked = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onseeking = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onsuspend = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.ontimeupdate = function (e) {
    //     console.log("AUDIO EVENT: ", e)
    // };

    // streamSource.onvolumechange = function() {
    //   console.log("AUDIO EVENT: ", e);
    // };
  },
  methods: {
    ...mapActions("streamPlayer", ["getPlayingNow"]),
    getStreamUrlNoCache: function() {
      return getStreamUrl() + 
        "?nocache=" + 
        Math.random()
          .toString()
          .slice(2)
    },
    playPause: function() {
      var streamSource = this.$refs.streamsource;
      if (streamSource.paused) {
        streamSource.play();
        this.setPlayingSrc({
          src: streamSource.src
        });
        this.setPlayingTitle(this.getNowPlaying(this.streamPlayer.playingNow));
      } else {
        streamSource.pause();
      }

      if (this.$ga) {
        this.$ga.event({
          eventCategory: "userAction",
          eventAction: "playPause"
        });
      }

      // Prevent Default Action
      return false;
    },
    play: function() {
      var streamSource = this.$refs.streamsource;
      streamSource.play();
    },
    pause: function() {
      var streamSource = this.$refs.streamsource;
      streamSource.pause();
    },
    formatArtists: function(artists) {
      if (artists && artists.length > 0) {
        return (
          "by " +
          artists
            .map(function(elem) {
              return elem.name;
            })
            .join(" & ")
        );
      };
      return "";
    },
    formatDate: function(date) {
      return date ? new Date(date).toLocaleDateString("en-US", {
        month: "2-digit",
        year: "2-digit"
      }) : "";
    },
    getNowPlaying: function(playingNow) {
      if (playingNow.prerecorded) {
        const title = [
          playingNow.title,
          this.formatDate(playingNow.record_date),
          this.formatArtists(playingNow.artists)
        ];
        return title.join(" ");
      } else if (playingNow.live) {
        return playingNow.title + " " + this.formatArtists(playingNow.artists);
      } else {
        const title = [
          playingNow.title,
          this.formatDate(playingNow.record_date),
          this.formatArtists(playingNow.artists)
        ];
        return title.join(" ");
      }
    },
    ...mapMutations("docTitle", ["setPlayingTitle"]),
    ...mapMutations("audioPlayer", ["setPlayingSrc"])
  }
};
</script>

<style scoped lang="scss">
@import "../../variables";
audio {
  height: 0;
  width: 0;
  display: none;
}
.stream-player-metadata-title {
    color: $navigation-color-alternative;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

}
</style>
