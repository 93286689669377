import radio from "../api";

const state = {
  active: true,
  playingNow: {
    title: "Waiting for stream...",
    artists: []
  },
  playerState: "",
  isLoading: false,
  playing: false,
  contentChanged: false,
  videoStream: false,
  videoUrl: ""
};

const getters = {
  isPlayingStreamPlayer: function(state) {
    return state.playing;
  },
  changedContent: function(state) {
    return state.contentChanged;
  },
  videoStream: function(state) {
    return state.videoStream;
  },
  videoUrl: function(state) {
    return state.videoUrl;
  }
};

const actions = {
  getPlayingNow({ commit, dispatch }) {
    commit("setLoadingState", true);
    radio
      .getPlayingNow()
      .then(response => {
        commit("setLoadingState", false);
        commit("setPlayingNow", response.data);
        dispatch("archiveSelection/updateCsrfToken", response.csrftoken, {
          root: true
        });
      })
      .catch(error => {
        error;
      });
  },
  updatePlayerState({ commit }, playerEventName) {
    commit("setPlayerState", playerEventName);
  }
};

const mutations = {
  setActiveState(state, bool) {
    state.active = bool;
  },

  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setPlayingNow(state, resultData) {
    if (state.playingNow && resultData.title == state.playingNow.title) {
      state.contentChanged = false;
    } else {
      state.playingNow = resultData;
      state.contentChanged = true;
    }
    state.videoStream = resultData.video;
    state.videoUrl = resultData.video_url;
  },

  setPlayerState(state, playerEventName) {
    state.playerState = playerEventName;
    if (playerEventName == "playing") {
      state.playing = true;
    } else {
      state.playing = false;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
