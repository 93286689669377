const state = {
  src: "Waiting for update...",
  // playlist can contain multiple same recordings
  // position is to handle this situation
  position: null
};

const getters = {
  getPlayingSrc: function(state) {
    return { src: state.src, position: state.position };
  }
};

const actions = {};

const mutations = {
  setPlayingSrc(state, { src, position }) {
    state.src = src;
    state.position = position;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
