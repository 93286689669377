import Vue from "vue";
import Vuex from "vuex";

import archive from "./archive";
import navigation from "./navigation";
import audioPlayer from "./audioPlayer";
import streamPlayer from "./streamPlayer";
import archivePlayer from "./archivePlayer";
import archiveProgram from "./archiveProgram";
import archiveSelection from "./archiveSelection";
import archiveListing from "./archiveListing";
import shows from "./shows";
import closedShows from "./closedShows";
import calendar from "./calendar";
import homePage from "./homePage";
import events from "./events";
import search from "./search";
import docTitle from "./docTitle";
import chat from "./chat";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    archive: archive,
    shows: shows,
    closedShows: closedShows,
    navigation: navigation,
    audioPlayer: audioPlayer,
    streamPlayer: streamPlayer,
    archivePlayer: archivePlayer,
    archiveProgram: archiveProgram,
    archiveSelection: archiveSelection,
    archiveListing: archiveListing,
    docTitle: docTitle,
    calendar: calendar,
    homePage: homePage,
    events: events,
    search: search,
    chat: chat
  },
  strict: "debug"
});
