<script>
export default {
  name: "IrcLink",
  template: "#irc-link-chat-component-template",
  components: {},
  props: ["msg"],
  computed: {},
  data: function() {
    return {};
  },
  created() {},
  methods: {
    clickHandler: function() {
      this.$root.$emit("internal_message_clicked");
    }
  }
};
</script>

<template id="irc-link-chat-component-template">
  <span>
    <span>Listen</span>&nbsp;
    <router-link :to="msg.content.route" tag="a" @click.native="clickHandler">{{
      msg.content.title
    }}</router-link>
  </span>
</template>

<style scoped lang="scss">
@import "../../variables";
</style>
