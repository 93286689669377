import radio from "../api";

const state = {
  active: false,
  playing: false,
  loading: false,
  loaded: false,
  seeking: false,
  item: {
    show: "Waiting for update...",
    artists: [],
    tags: [],
    slug: "waiting",
    hls_playlist: null,
    audio_duration: null,
    waveform_data: null
  },
  playerState: "",
  autoplay: true,
  queue: [],
  is_hidden: false,
  waveformData: []
};

const getters = {
  isActiveArchivePlayer: function(state) {
    //console.log("called isActiveArchivePlayer", state.active);
    return state.active;
  },
  isPlayingArchivePlayer: function(state) {
    return state.playing;
  },
  getFirstQueueItem: function(state) {
    return state.queue[0];
  },
  getWaveform: function(state) {
    return state.waveformData;
  }
};

const actions = {
  updatePlayerState({ commit }, playerEventName) {
    commit("setPlayerState", playerEventName);
  },
  removeQueueItem({ commit, state }) {
    if (state.queue.length > 0) {
      const items = state.queue.slice(1);
      commit("setQueueItems", items);
    }
  },
  setPlayerActiveState({ commit }, bool) {
    commit("setActiveState", bool);
  },
  updateQueueItems({ commit }, items) {
    commit("setQueueItems", items);
  },
  toggleAutoplay({ commit }) {
    commit("toggleAutoplay");
  },
  addToQueue({ commit }, { item }) {
    commit("addQueueItem", { item: item, position: "top" });
  },
  async updateWaveform({ commit }, url) {
    await radio
      .getWaveformData(url)
      .then(response => {
        commit("setWaveform", response.data);
      })
      .catch(error => {
        error;
      });
  }
};

const mutations = {
  toggleAutoplay(state) {
    state.autoplay = !state.autoplay;
  },

  setActiveState(state, bool) {
    state.active = bool;
  },

  setLoadingState(state, { source, item, bool }) {
    state.loading = bool;
    state.loaded = !bool;
    state.source = source;
    state.item = item;
    state.playing = false;
  },
  setLoadedState(state, { source, item, bool }) {
    state.loaded = bool;
    state.loading = !bool;
    state.source = source;
    state.item = item;
    state.item.audio_duration = parseInt(item.audio_duration);
    state.playing = false;
  },
  setSeekingState(state, { bool }) {
    state.seeking = bool;
  },
  setPlayingState(state, { bool }) {
    state.playing = bool;
  },
  setPlayerState(state, playerEventName) {
    state.playerState = playerEventName;
    if (playerEventName == "playing" || playerEventName == "play") {
      state.playing = true;
      state.loading = false;
    } else if (playerEventName == "waiting") {
      state.playing = false;
      state.loading = true;
    } else {
      state.playing = false;
      state.loading = false;
    }
  },
  addQueueItem(state, { item, position }) {
    if (position == "top") {
      state.queue.unshift(Object.assign({}, item));
    } else if (position == "bottom") {
      state.queue.push(Object.assign({}, item));
    }
  },
  setQueueItems(state, items) {
    state.queue = items;
  },
  setWaveform(state, data) {
    state.waveformData = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
