import radio from "../api";

const state = {
  all: undefined,
  isLoading: false,
  recentEvent: undefined
};

const getters = {};

const actions = {
  loadEvents({ commit, state }) {
    if (!state.all) {
      commit("setLoadingState", true);
      radio.getEvents().then(response => {
        commit("setLoadingState", false);
        commit("setEvents", response);
      });
    }
  },
  loadEventDetail({ commit }, slug) {
    commit("setLoadingState", true);
    radio.getEventDetail(slug).then(response => {
      commit("setLoadingState", false);
      commit("setRecentEvent", response);
    });
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setEvents(state, resultData) {
    state.all = resultData;
  },

  setRecentEvent(state, resultData) {
    state.recentEvent = resultData.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
