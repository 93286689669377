const state = {
  isNavigationOpened: false,
  isSearchOpened: false
};

const getters = {
  getSearchIsOpened: function(state) {
    return state.isSearchOpened;
  }
};

const actions = {
  openSearch({ commit }) {
    commit("updateSearchState", true);
  },
  closeSearch({ commit }) {
    commit("updateSearchState", false);
  },
  toggleNavigation({ commit }) {
    commit("toggleNavigationState");
  },
  closeNavigation({ commit }) {
    commit("setNavigationState", false);
  }
};

const mutations = {
  updateSearchState(state, bool) {
    state.isSearchOpened = bool;
  },

  toggleNavigationState(state) {
    state.isNavigationOpened = !state.isNavigationOpened;
  },

  setNavigationState(state, bool) {
    state.isNavigationOpened = bool;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
