import radio from "../api";

const state = {
  all: undefined,
  isLoading: false
};

const getters = {
  getCalendar: state => {
    return state.all;
  }
};

const actions = {
  loadCalendar({ commit, state }, page) {
    if (!state.all) {
      commit("setLoadingState", true);
      radio.getCalendar(page).then(response => {
        commit("setLoadingState", false);
        commit("setCalendar", response);
      });
    }
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setCalendar(state, resultData) {
    //console.log('set calendar', resultData);
    state.all = resultData;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
