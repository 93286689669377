<template>
  <footer>
    <section class="container">
      <article>
        <div class="footer-brand">
          <img
            class="footer-logo"
            alt="Radio Punctum Logo"
            src="../assets/radio-punctum-logo-footer.png"
          />
        </div>
        <section class="footer-contact">
          <header class="footer-title">
            <a href="mailto:contact@radiopunctum.cz">contact@radiopunctum.cz</a>
          </header>
          Prague, Czech republic
        </section>
        <section v-if="displaySocialLinks" class="footer-social-media">
          <a
            href="https://soundcloud.com/radiopunctum"
            target="_blank"
            title="Soundcloud"
            rel="noreferrer"
            ><span class="icon-soundcloud"></span
          ></a>
          <a
            href="https://punctumtapes.bandcamp.com/"
            target="_blank"
            title="Bandcamp"
            rel="noreferrer"
            ><span class="icon-bandcamp"></span
          ></a>
          <a
            href="https://www.instagram.com/radio_punctum/"
            target="_blank"
            title="Instagram"
            rel="noreferrer"
            ><span class="icon-instagram"></span
          ></a>
          <!--
          <a
            href="https://www.patreon.com/punctum"
            target="_blank"
            title="Patreon"
            rel="noreferrer"
            ><span class="icon-patreon"></span
          ></a>
          -->
        </section>
      </article>
    </section>
    <section class="content-footer-lower">
      <clausules />
      <div>
        All Rights Reserved ©{{ new Date().getFullYear() }} Radio Punctum
      </div>
    </section>
  </footer>
</template>
<script>
// @ is an alias to /src
import Clausules from "@/components/Clausules.vue";
import { isMobile } from "@/helpers.js";

export default {
  name: "FooterComponent",
  components: {
    Clausules
  },
  computed: {
    displaySocialLinks: function() {
      if (!isMobile()) {
        return true;
      } else {
        return ["Home", "Calendar", "About"].includes(this.$route.name);
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../variables";
@import "../mixins";

footer {
  article {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "social-media"
      "logo"
      "contact";

    @media (min-width: $screen-md-min) {
      grid-template-columns: auto 1fr 1fr;
      grid-template-areas:
        "social-media social-media social-media"
        "logo contact contact";
    }

    @media (min-width: $screen-lg-min) {
      grid-template-columns: auto 1fr auto auto;
      grid-template-areas: "logo contact contact social-media";
    }

    header {
      display: block;
      white-space: nowrap;

      @media (min-width: $screen-md-min) {
        margin-top: 0;
      }
    }

    section {
      font-size: 18px;
      line-height: 1.4;

      @media (min-width: $screen-sm-min) {
        font-size: 20px;
      }

      @media (min-width: $screen-md-min) {
        padding-left: $spacing-default * 2;

        &:first-child {
          padding-left: 0;
        }
      }

      a {
        color: $txt-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .footer-brand {
      padding: $base-unit * 6 $base-unit * 3 0 $base-unit * 3;

      @media (min-width: $screen-sm-min) {
        padding: $base-unit * 6 0 0 $base-unit * 6;
      }

      @media (min-width: $screen-md-min) {
        padding: $base-unit * 4 0 0 $base-unit * 6;
      }
    }

    .footer-logo {
      display: block;
      width: 40%;
      height: 100%;
      grid-area: logo;

      @media (min-width: $screen-sm-min) {
        width: auto;
        height: auto;
        max-width: 220px;
        margin: 0 0 $spacing-default;
      }

      @media (min-width: $screen-md-min) {
        margin: 0 0 $spacing-default;
      }
    }

    .footer-title {
      font-family: $title-font;
      font-weight: bold;
      text-transform: uppercase;

      @media (max-width: $screen-xxs-min) {
        font-family: $sans-serif;
      }
    }

    .footer-contact {
      margin: $spacing-default $base-unit * 3 0 $base-unit * 3;
      grid-area: contact;
      white-space: nowrap;

      @media (min-width: $screen-sm-min) {
        margin: $spacing-default $base-unit * 3 0 $base-unit * 6;
      }

      @media (min-width: $screen-md-min) {
        margin: $base-unit * 3 0 0 0;
      }

      @media (min-width: $screen-lg-min) {
        margin: $base-unit * 5 $base-unit * 3 0 0;
      }
    }

    .footer-social-media {
      grid-area: social-media;
      background: $bg-color-alternative;
      text-align: center;
      padding: $spacing-default 0;

      @media (min-width: $screen-lg-min) {
        padding: $spacing-default;
        background: none;
      }

      a {
        padding: $base-unit * 2;
        font-size: 20px;

        @media (min-width: $screen-xxxs-min) {
          font-size: 25px;
        }

        @media (min-width: $screen-sm-min) {
          margin: $base-unit * 2 $base-unit * 5 $base-unit * 2 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    .icon-soundcloud,
    .icon-mixcloud {
      display: inline-block;
      transform: scale(1.3);
      margin-left: 8px;
    }
  }

  .content-footer-lower {
    display: flex;
    padding: $base-unit * 5 $base-unit * 3 $base-unit * 4;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    color: $footer-lower-anchor-color;

    @media (min-width: $screen-sm-min) {
      padding: $base-unit * 5 $base-unit * 5 $base-unit * 4 $base-unit * 6;
      flex-direction: row;
    }

    a {
      margin-right: $spacing-default;
      color: $footer-lower-anchor-color;
      text-decoration: underline;
    }
  }
}
</style>
