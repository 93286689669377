<script>
import IrcLink from "@/components/Chat/IrcLink.vue";

export default {
  name: "InternalMessage",
  template: "#internal-message-component-template",
  components: {
    IrcLink
  },
  props: ["msg"],
  computed: {},
  data: function() {
    return {
      query: null
    };
  },
  created() {},
  methods: {
    formatMsgTime: function(msgTime) {
      const today = new Date().setHours(0, 0, 0, 0);
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.setHours(0, 0, 0, 0);
      const msgDate = new Date(msgTime).setHours(0, 0, 0, 0);
      if (msgDate == today) {
        return (
          "Today at " +
          new Date(msgTime).toLocaleTimeString("en-US", {
            hour12: true,
            timeStyle: "short"
          })
        );
      } else if (msgDate == yesterday) {
        return (
          "Yesterday at " +
          new Date(msgTime).toLocaleTimeString("en-US", {
            hour12: true,
            timeStyle: "short"
          })
        );
      } else {
        return new Date(msgTime).toLocaleString("en-US", {
          hour12: true,
          dateStyle: "long",
          timeStyle: "short"
        });
      }
    }
  }
};
</script>

<template id="internal-message-component-template">
  <div class="message self">
    <span class="prefix">
      {{ msg.prefix.nick }}
      <span v-if="msg.tags.time" class="message-time">
        {{ formatMsgTime(msg.tags.time) }}
      </span>
    </span>
    <span class="params">
      <IrcLink :msg="msg" />
    </span>
  </div>
</template>

<style scoped lang="scss">
@import "../../variables";
.message {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  margin-top: -4px;
  padding: 0 0 5px;
  display: flex;
  line-height: 1.2em;
  flex-direction: column;
  width: 100%;
  .prefix {
    color: $color-secondary;
    .message-time {
      color: grey;
      font-size: 75%;
    }
  }
  * {
    overflow-wrap: anywhere;
  }
}
</style>
