/*
 * mixins for use in components
 */

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import cookie from "vue-cookie";

export const pageTitleMixin = {
  computed: {
    ...mapState({
      docTitle: state => state.docTitle
    }),
    ...mapGetters("streamPlayer", ["isPlayingStreamPlayer"]),
    ...mapGetters("archivePlayer", ["isPlayingArchivePlayer"])
  },
  watch: {
    isPlayingStreamPlayer: function(newVal) {
      if (newVal) {
        document.title = this.docTitle.playingTitle;
      } else {
        document.title = this.docTitle.pageTitle;
      }
    },
    isPlayingArchivePlayer: function(newVal) {
      if (newVal) {
        document.title = this.docTitle.playingTitle;
      } else {
        document.title = this.docTitle.pageTitle;
      }
    }
  },
  methods: {
    updatePageTitle: function(title) {
      this.setPageTitle(title);
      if (!this.isPlayingArchivePlayer && !this.isPlayingStreamPlayer) {
        document.title = title;
      }
    },
    ...mapMutations("docTitle", ["setPageTitle"])
  }
};

export const loadSelectionMixin = {
  computed: {
    ...mapState({
      playlist: state => state.archiveSelection
    })
  },
  mounted: function() {
    let sessionId = cookie.get("sessionid");
    if (
      sessionId &&
      ["Playlist", "SharedPlaylist"].includes(this.$route.name)
    ) {
      this.loadSelection({ sessionId: sessionId }).then(() => {
        const archivePlayer = this.$root.$children[0].$refs.archivePlayer;
        const queueItems = this.getSelectionItems();
        archivePlayer.updateQueueItems(queueItems);
      });
    }
  },
  methods: {
    ...mapGetters("archiveSelection", ["getSelectionItems"]),
    ...mapActions("archiveSelection", ["loadSelection"])
  }
};
