<template id="genre-link-template">
  <router-link
    class="genre-link tag"
    :to="{ name: 'GenreArchive', params: { genre: genre.tag } }"
  >
    {{ genre.tag }}
  </router-link>
</template>

<script>
/* eslint-disable */
export default {
    name: "genre-link",
    template: "#genre-link-template",
    props: [
        "genre"
    ],
    computed: {
    },
    data: function() {
        return {
        }
    }
};
</script>

<style scoped lang="scss">
@import "../variables";
</style>
