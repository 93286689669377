import radio from "../api";
import cookie from "vue-cookie";
import { setItemsOrder, isMobile } from "@/helpers.js";

const state = {
  title: "Playlist",
  items: [],
  playing: false,
  selectionId: undefined,
  csrftoken: undefined,
  loaded: false,
  loading: false
};

const getters = {
  getSelectionItems: function(state) {
    return state.items;
  },
  getTitle: function(state) {
    return state.title;
  },
  getLoading: function(state) {
    return state.loading;
  },
  getLoaded: function(state) {
    return state.loaded;
  }
};

const actions = {
  async addToSelection({ dispatch, commit }, { item, position }) {
    commit("addItem", { item: item, position: position });
    await dispatch("saveSelection");
  },
  async removeFromSelection({ dispatch, commit }, { item }) {
    commit("removeItem", { item: item });
    await dispatch("saveSelection");
  },
  async updateSelectionItems({ dispatch, commit }, { items }) {
    //let ordered = setItemsOrder(items);
    commit("updateItems", { items: items });
    if (!isMobile()) {
      await dispatch("saveSelection");
    }
  },
  updateTitle({ commit }, { title }) {
    //let ordered = setItemsOrder(items);
    commit("updateTitle", { title: title });
  },
  setSelectionPlayingState({ commit }, bool) {
    commit("setPlayingState", bool);
  },
  loadSelection({ commit }, { sessionId, selectionId }) {
    commit("setLoading");
    return new Promise((resolve, reject) => {
      radio
        .getPlaylist(sessionId || selectionId)
        .then(response => {
          //let ordered = setItemsOrder(response.data.tracks);
          commit("updateItems", { items: response.data.tracks.selection });
          commit("updateTitle", {
            title: response.data.tracks.title || "Playlist"
          });
          if (sessionId) {
            commit("setSelectionId", response.data.playlistId);
          } else {
            // dont allow to update shared playlist by viewing user
            commit("setSelectionId", null);
          }
          commit("setLoaded");
          resolve("Loaded");
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async saveSelection({ commit }) {
    let data = {
      uid: cookie.get("sessionid"),
      tracks: {
        selection: state.items.map(item => item.uuid),
        title: state.title
      }
    };
    let selectionId = state.selectionId;
    let csrftoken = state.csrftoken;
    return new Promise((resolve, reject) => {
      radio
        .saveSelection(data, selectionId, csrftoken)
        .then(response => {
          commit("updateCsrfToken", response.csrftoken);
          commit("setSelectionId", response.data.playlistId);
          resolve(response.data.playlistId);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateSelectionId({ commit }, selectionId) {
    commit("setSelectionId", selectionId);
  },
  updateCsrfToken({ commit }, token) {
    commit("setCsrfToken", token);
  }
};

const mutations = {
  addItem(state, { item, position }) {
    if (position == "top") {
      state.items.unshift(Object.assign({}, item));
    } else if (position == "bottom") {
      state.items.push(Object.assign({}, item));
    }
    state.items = setItemsOrder(state.items);
  },
  removeItem(state, { item }) {
    state.items.splice(item.order, 1);
    state.items = setItemsOrder(state.items);
    //state.items = state.items.filter(function(citem) {
    //  return citem.recording_name !== item.recording_name;
    //});
  },
  updateItems(state, { items }) {
    state.items = setItemsOrder(items);
  },
  updateTitle(state, { title }) {
    state.title = title;
  },
  setPlayingState(state, bool) {
    state.playing = bool;
  },
  setSelectionId(state, selectionId) {
    state.selectionId = selectionId;
  },
  setCsrfToken(state, token) {
    state.csrftoken = token;
  },
  setLoading(state) {
    state.loading = true;
    state.loaded = false;
  },
  setLoaded(state) {
    state.loaded = true;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
